* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  justify-content: center;
}

.content-container {
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #fffbf0;
}

.hiddenAppLogo {
  display: none;
}

/* ------------------ NAVBAR --------------------*/
.coupidLogo {
  cursor: pointer;
}

.coupidLogo a img{
  max-width: 140px;
  margin-left: 25px;
}

.navbar {
  padding: 2rem 4rem 0.5rem 5rem;;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: bold;
  background-color: #fffbf0
}

.nav-list {
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.nav-list li {
  list-style: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 2rem;
  align-items: center;
  
}

.nav-list a{
  text-decoration: none;
  color: black;
}


.burgerMenu {
  display: none;
}


.burgerMenu div{
  width: 30px;
  height: 4px;
  margin: 6px;
  background-color: grey;
}

/* ------------------Navbar ------------------- */






/* ------------------ Bottom Navbox and Footer ------------------------*/

.bottom-navbox {
  background-color: #F7BF4F;
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 0;
  flex-wrap: wrap;
}

.contact-details {
  font-family: sans-serif;
  padding: 2rem 5rem 4rem 5rem;
  font-size: large;
  letter-spacing: 0.7px;
  line-height: 30px;
  text-align: left;
}

.bottom-links {
  display: flexbox;
  padding: 0rem 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.bottom-links li {
  padding: rem;
  width:210px;
}

.bottom-links a {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  text-decoration: none;
  color: black;
  font-weight: 300;
}

.bottom-links a:hover {
  color: brown;
  text-decoration: underline;
}

.linktable{
  font-family: sans-serif;
  padding: 0.5rem;
  letter-spacing: 2px;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  font-weight: bold;
  line-height: 35px;
  flex-wrap: wrap;
}

.linktable p {
  font-weight: 500;
  width: max-content;
  flex-wrap: column wrap;
}


footer {
  position: relative;
  justify-content: center;
  text-align: center;
  bottom: 0;
  padding-bottom: 1.5rem;
  width: 100%;
  background-color: #F7Bf4F;
  padding: 1rem;
  font-family: sans-serif;
  font-weight: 200;
  font-size: x-small;
  color: black;
  display: flex;
}

footer img {
  height: 13px;
  padding: 0rem 1rem 0rem 0rem;
}

.socials {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: left;
}

.iconContainer {
  display: flex;
  justify-content: left;
  align-content: center;
}

.socialicon {
  height: 15px;
  width: 15px;
  margin: 1rem 1rem;
}

.socialicon:hover {
  cursor: pointer;
}


/* ------------------ Bottom Navbox and Footer ------------------------*/

/* ------------------- Home Page Content ---------------------------*/

.homePageContainer {
  padding: 1rem 0rem 2rem 0rem;
  background-color: #fffbf0;
}

.homePageContainer .herocontainer {
  padding: 3rem 5rem;
}

.homeTopHero {
  background-color: #F7BF4F;
}

.homeTopHero button {
  background-color: rgb(241, 241, 241);
  cursor: pointer;
}

.homeTopHero button:hover {
  background-color: rgb(166, 102, 39);
  cursor: pointer;
  color: white;
}

.homeBottomHero button {
  background-color: rgb(241, 241, 241);
  cursor: pointer;
}

.homeBottomHero button:hover {
  background-color: rgb(166, 102, 39);
  cursor: pointer;
  color: white;
}

.homeBottomHero .herocard .hero-content{
  min-width: 50%;
}

.promoVid {
  display: flex;
  padding: 10rem 3rem 10rem 3rem;
  border-style: none;
  height: 1000px;
  /* width:90%; */
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s ease;
  transform-origin: center;
}

.promoVid iframe {
  border-style: none;
  min-width: 100%
}

.subTitle {
  padding: 1rem 5rem 5rem 5rem;
  word-spacing: 5px;
}


/* ------------------- Home Page Content ---------------------------*/


/* ------------- Content Error Format --------------------- */
.contenterror {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5rem 10rem;
}

.contenterror img {
  max-height: 400px;
  align-self: center;
}

.contenterror h1 {
  font-size: 40px;
  padding: 2rem 0rem;
}

.contenterror p {
  line-height: 2rem;
  font-size: 25px;
}
/* ------------- Content Error Format --------------------- */


/* ------------- Partnership Title --------------------- */


.partnerTitle {
  display: flex;
  background-color: #F7BF4F;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 0rem 7rem 0rem;
  padding: 3rem 8em 0rem 8rem;
}

.partnerTitle h1 {
  display: flex;
  justify-content: flex-start;
  flex-basis: auto;
  padding: 2rem 0rem;
  width: 50%;
}

.partnerTitle p {
  display: flex;
  justify-content: flex-start;
  flex-basis: auto;
  line-height: 25px;
  width: 50%;
}

.eoiForm {
  background-color: white;
  margin: 1rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  position: relative;
  top: 4rem;
  left: -2rem;
  box-shadow: 7px 7px 50px grey;
  flex-basis: auto;
  width: 50%;
  z-index: 1;
  
}

.eoiForm h2 {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.eoiForm p {
  display: flex;
  width: 100%;
  line-height: 1rem;
  padding: 1rem 0rem;
}

.eoiField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
}

.eoiField input {
  height: 3rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 4px lightgrey;
  border: 1px solid black;
  width: 100%;
  padding: 0rem 1rem;
  font-size: large;
}

.eoiField #input:hover {
  border: 2px solid brown;
}

.eoiField ::placeholder {
  font-size: large;
  font-weight: bold;

}

.eoiField :focus{
  font-size: large;
  border: 2px solid brown;
}

.eoiField button {
  margin: 1rem 0rem 0rem 0rem;
}

.whyeGifts .herocard .hero-img{
  max-width: 10%;
}


/* ------------- Partnership Title --------------------- */


/* ------------- Partnership Content --------------------- */
.partnerContent {
  padding: 0rem 5rem;
}

.partnerContent #howitworks {
  display: flex;
  justify-content: flex-start;
  padding: 7rem 0rem 5rem 1rem;
}
/* ------------- Partnership Content --------------------- */

/* -----------------About Us ------------------------*/


.aboutUsContent {
  width: 100%;
  padding: 2rem 0rem;
}

.aboutUsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.aboutUsBanner{
  padding: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #F7BF4F;
}

.aboutUsBanner img {
  padding: 0rem 5rem;
  width: 50%;
  height: auto;
}

.aboutUsContainer p {
  padding: 1rem 0rem;
  font-size: large;
  line-height: 30px;
}

.aboutUsContainer h1 {
  padding: 0rem 0rem 2rem 0rem;
}

.aboutUsHero {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;

}


/* -----------------About Us ------------------------*/


/*------------------ Terms and Condition -----------------*/
.termsandcondition {
  padding: 5rem;
}

.termsandcondition p {
  padding: 5rem 0rem;
}
/*------------------ Terms and Condition -----------------*/

/*------------------ Tutorial -----------------*/
.tutorial {
  padding: 5rem;
}
.tutorial p {
  padding: 5rem 0rem;
}
/*------------------ Tutorial -----------------*/


/*------------------ Careers -----------------*/
.careers {
  padding: 5rem;
}

.careers p {
  padding: 5rem 0rem;
}
/*------------------ Careers -----------------*/

/*------------------ Legal and Privacy -----------------*/
.legal {
  padding: 5rem;
}

.legal p {
  padding: 5rem 0rem;
}
/*------------------ Legal and Privacy -----------------*/


/*------------------- Hero Containers ---------------------*/

.herocard {
  display: flex;
  justify-content: flex-start;
  padding: 5rem 10rem;
}

.hero-img {
  object-fit: scale-down;
  max-height: 500px;
  min-width: 50%;
  padding: 0rem 1rem;
}

.hero-content {
  min-width: 50%;
  padding: 0rem 1rem;
}

.hero-content h3 {
  font-weight: 700;
  font-size: large;
  padding: 0.5rem 0rem 1rem 0rem;
  color: rgb(80, 80, 80);
}

.hero-content h2 {
  line-height: 2rem;
  font-size: x-large;
}

.hero-content p {
  padding: 1rem 0rem;
  line-height: 2rem;
  font-size: medium;
  color: rgb(80, 80, 80);
}

button {
  border: none;
  padding: 1rem 3rem;
  border-radius: 2rem;
  font-size: larger;
  font-weight: bold;
  color: #7E4300;
  background-color: #F7BF4F;
  transition: 0.4s;
  cursor: pointer;
}

button:hover {
  background-color: rgb(241, 241, 241);
}

.partnerContent .herocard {
  padding: 3rem 0rem;
}

.partnerContent .herocard .hero-content {
  min-width: 50%;
}
.partnerContent .herocard .hero-img {
  min-width: 50%;
}

.leftCircle .hero-content{
  background-image: url("./Images/bgcircle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-left: 8rem;
  padding-top: 5rem;
}

.rightCircle .hero-content{
  background-image: url("./Images/bgcircle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-left: 8rem;
  padding-top: 5rem;
}

/*------------------- Hero Containers ---------------------*/

/* ------------------eoiForm-------------------*/
.eoiForm {
  display: grid;
  padding: 5rem;
}

.formItem {
  padding: 1rem 0.5rem;
}

.formItem input {
  line-height: 30px;
  width: 100%;
}

/* ------------------eoiForm-------------------*/


/* ------------------ View Port Settings --------------------*/


@media screen and (min-width:846px) {
  .partnerTitle {
    padding: 3rem 8rem 1rem 8rem;
  }
  .partnerTitle h1{
    width: 70%;
  }
  .partnerTitle p {
    width: 50%;
  }
  .eoiForm {
    width: 70%;
  }
  .eoiForm p {
    width: auto;
  }
}


@media screen and (max-width:845px) {

  .navbar {
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem 0.5rem;
  }

  .nav-list {
      display: none;
  }

  .burgerMenu{
      display: block;
      cursor: pointer;
  }

  .line1.cross {
    transform: rotate(45);
  }
  .line2.cross {
    display: "hidden";
  }
  .line3.cross {
    transform: rotate(-45);
  }

  .nav-list.responsive {
      display: block;
      position: fixed;
      top: 7rem;
      right: 0rem;
      height: 100%;
      width: 100%;
      background-color: #f7bf4ff3;
      overflow-x: hidden;
      transition: 0.5s;
      z-index: 2;
  }


  .nav-list li {
      position: relative;
      text-align: center;
      padding: 3rem;
      font-size: x-large;
      width:100%;
  }

  .nav-list a {
      display: flex;
      padding: 1rem;
      width: 100%;
      transition: 0.4s;
  }

  .nav-list a:hover {
      background-color: #f6d594f3;
      color: white;
      padding: 1rem;
      border-radius: 2rem;
  }

  .burgerMenu{
      display: block;
      cursor: pointer;
  } 

  .partnerTitle {
    padding: 3rem 3rem 0rem 3rem;
  }
  
  .partnerTitle p {
      display: flex;

      flex-basis: auto;
      width: 100%;
  }

  .partnerTitle h1 {
      display: flex;
      justify-content: flex-start;
      flex-basis: auto;
      width: 100%;
  }

  .eoiForm {
      width: 100%;
      padding: 2rem 2rem;
  }

  .herocard {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    padding: 3rem 0rem 3rem 0rem;
  }

  .herocard#heroR {
    flex-direction: column-reverse;
  }

  .hero-content {
    max-width: 100%;
    padding: 3rem 2rem;
  }

  .hero-img {
    max-width: 100%;
    padding: 1rem;
  }

  .aboutUsTitle {
    padding: 3rem;
  }

  .aboutUsBanner {
    flex-direction: column;
  }

  .aboutUsBanner img {
    width: 80%;
  }
  .promoVid {
    padding: 5rem 1rem 3rem 1rem;
    height: 500px;
  }

  .linktable li {
    padding: 1.5rem 0rem;
  }
}


/* ------------------ View Port Settings --------------------*/